import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../../utils/LayoutUtils";
import { ListDataShow } from "../../../../../components/Compnent";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomPopup from "../../../../../components/CustomPopup";
import { IoCheckmarkCircle } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import StatusComp from "../../../../../components/StatusComp";

const AdviceOrder = () => {
  const [list, setlist] = useState([1, 2, 2, 3]);
  const [loading, setLoading] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [id, setId] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "notificationlist");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id);
      body.append("service_provider", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      console.log("res  ===", res);
      if (res.success) {
        setlist(res?.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  const status_update = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "updatenotification");
      body.append(
        "status",
        updateType == "Accept" ? "1" : updateType == "Reject" ? "2" : ""
      );
      body.append("id", id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        fetchData();
        onClose();
        toast.success(res?.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error =====", error);
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Orders"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: -3 },
          { name: "Get Advice", to: -2 },
          { name: state?.name, to: -1 },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {loading ? (
          <Loader />
        ) : list.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          list.map((v, i) => (
            <Box
              key={i}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              border={`1px solid ${
                v?.status == "0"
                  ? "orange"
                  : v?.status == "1"
                  ? "green"
                  : v?.status == "2"
                  ? "red"
                  : "orange"
              }`}
              gap={[5, "5%"]}
              position={"relative"}
            >
              {/* {v?.status != "0" ? (
                <Text position={"absolute"} right={2} top={2}>
                  {v?.status == "1" ? (
                    <IoCheckmarkCircle size={35} color={"green"} />
                  ) : v?.status == "2" ? (
                    <RxCrossCircled size={35} color={"red"} />
                  ) : (
                    ""
                  )}
                </Text>
              ) : (
                ""
              )} */}
               <StatusComp status={v?.status} />
              <Flex flexDirection={"column"} w={"100%"}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  //   title={"Name"}
                  value={v?.Agency_name}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  //   title={"Description"}
                  value={v?.title}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  //   title={"Name"}
                  value={v?.message}
                />
                {v?.status == "1" && (
                  <>
                    <ListDataShow
                      f1={[4, 6]}
                      f2={[7, 14]}
                      //   title={"Name"}
                      value={v?.Email_Address}
                    />
                  </>
                )}
              </Flex>
              <Flex
                mt={2}
                w={["full", v?.status == "0" ? "70%" : "50%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"3"}
              >
                {v?.status == "0" ? (
                  <>
                    <CustomButton
                      w="full"
                      title={"Accept"}
                      bg={"brand.primary"}
                      onClick={() => {
                        setId(v?.ID);
                        setUpdateType("Accept");
                        onOpen();
                      }}
                    />
                    <CustomButton
                      w="full"
                      title={"Reject"}
                      bg={"brand.btn"}
                      onClick={() => {
                        setId(v?.ID);
                        setUpdateType("Reject");
                        onOpen();
                      }}
                    />
                  </>
                ) : v?.status == "1" ? (
                  <CustomButton
                    w="full"
                    title={"Complete"}
                    bg={"brand.primary"}
                    // onClick={() => {
                    //   setId(v?.ID);
                    //   setUpdateType("Accept");
                    //   onOpen();
                    // }}
                  />
                ) : (
                  ""
                )}

                <CustomButton
                  w="full"
                  title={"View/Download"}
                  bg={"black"}
                  onClick={() =>
                    navigate("/get-advice/order-details", {
                      state: {
                        state: state,
                        itemData: v,
                      },
                    })
                  }
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClick={status_update}
        b_name={updateType}
        onClose={onClose}
        title={updateType}
        mess={`Are you sure? You want to ${updateType} this Service.`}
      />
    </Container>
  );
};

export default AdviceOrder;
