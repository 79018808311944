import { Box, Container, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_category } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import {
  get_other_policy_list,
  get_policy_company_list,
} from "../../../../redux/otherSlice";
import ListItem from "../../../../components/ListItem";

const InsuranceCategory = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const { type } = useSelector((state) => state.otherData);
  const navigate = useNavigate();

  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", 8);
    store.dispatch(get_category(body));
    store.dispatch(get_policy_company_list());
    store.dispatch(get_other_policy_list());
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={20}
        mb={5}
      >
        <BreadCrumName
          current={"Insurance"}
          titlelist={[
            { name: "Home", to: "/" },
            type == 1 && { name: "Our Offering", to: "/home" },
          ]}
        />
        <CustomButton
          title={"View Quotation"}
          onClick={() => alert("Coming Soon...")}
        />
      </Flex>
      <Container maxW={"container.xl"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {cat_loading ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
            ]}
            gap={[5, 5, 10]}
          >
            {categoty_data?.map((item, index) => (
              <ListItem
                key={index}
                title={item.name}
                url={item.Web_Images}
                onClick={() => {
                  if (type == 0)
                    navigate("/insurance/add-insurance", {
                      state: { id: index },
                    });
                  else if (type == 1)
                    navigate("/insurance/requests", {
                      state: { id: index },
                    });
                }}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default InsuranceCategory;
