import {
  Center,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { images } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { CustomButton, InputBox } from "../utils/LayoutUtils";
import {
  FaTwitterSquare,
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa";
import CustomModal from "./CustomModal";
import { GoMail } from "react-icons/go";
import { MailLink } from "./Compnent";
import { contact_us_api } from "../utils/apis";
import { toast } from "react-toastify";

const Footer = ({ Ref, refund, privacy }) => {
  const [objData, setObjData] = useState({});
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPopOpen,
    onOpen: onPopOpen,
    onClose: onPopClose,
  } = useDisclosure();

  const mailtoLink = `mailto:${"naveen@veramasa.com"}?cc=${"naveen05patidar@gmail.com"}&subject=${encodeURIComponent(
    "Test Purpose"
  )}&body=${encodeURIComponent("Test Purpose")}`;

  const handleClick = () => {
    window.location.href = mailtoLink;
  };
  const validate = () => {
    const obj = {
      nameError: "",
      name_orgError: "",
      emailError: "",
      mobileError: "",
      specsError: "",
    };

    if (!objData?.name || objData?.name.trim().length === 0) {
      obj.nameError = "Please Enter Name";
    } else if (/\s{2,}/.test(objData.name)) {
      obj.nameError = "Only one space allowed between words";
    }

    if (!objData?.name_org || objData?.name_org.trim().length === 0) {
      obj.name_orgError = "Please Enter Organization Name";
    } else if (/\s{2,}/.test(objData.name_org)) {
      obj.name_orgError = "Only one space allowed between words";
    }
    if (!objData?.email || objData.email.trim().length === 0) {
      obj.emailError = "Please Enter Email";
    } else if (!/^\S+@\S+\.\S+$/.test(objData.email)) {
      obj.emailError = "Please Enter a Valid Email Address";
    }
    if (!objData?.mobile || objData.mobile.trim().length === 0) {
      obj.mobileError = "Please Enter Mobile";
    } else if (!/^\d{10}$/.test(objData.mobile)) {
      obj.mobileError = "Please Enter a Valid 10-digit Mobile Number";
    }
    if (!objData?.specs || objData?.specs.trim().length === 0) {
      obj.specsError = "Please Enter Specifications";
    }

    setObjData({ ...objData, ...obj });
  };
  const contact_us = async () => {
    const data = validate();
    if (
      objData?.nameError?.length == 0 ||
      objData?.name_orgError?.length == 0 ||
      objData?.emailError?.length == 0 ||
      objData?.mobileError?.length == 0 ||
      objData?.specsError?.length == 0
    ) {
      try {
        const body = new FormData();
        body.append("Name", objData?.name?.trim());
        body.append("Name_org", objData?.name_org?.trim());
        body.append("Email", objData?.email);
        body.append("Mobile_Number", objData?.mobile);
        body.append("Specification", objData?.specs?.trim());
        const responce = await contact_us_api(body);
        if (responce?.success) {
          toast.success(responce.message);
          setObjData({
            name: "",
            name_org: "",
            email: "",
            mobile: "",
            specs: "",
          });
        } else if (!responce?.success) {
          toast.success(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Flex
      w="100%"
      alignSelf={"center"}
      alignItems={"center"}
      gap={10}
      justifyContent={"space-between"}
      bg={"#2b3478"}
      p={10}
      flexDirection={["column", "column", "column", "row"]}
      ref={Ref}
    >
      <Flex gap={3} flexDirection={["column", "column", "column", "row"]}>
        <Image
          src={images.logo}
          h={["50px", "60px", "210"]}
          w={["50px", "60px", "200px"]}
          objectFit={"contain"}
          cursor={"pointer"}
          borderRadius={6}
          bg={"brand.white"}
          p={1}
          onClick={() => navigate("/")}
        />
        <Stack spacing={0}>
          <Text color={"#fff"} fontSize={20} p={0}>
            LPG Association
          </Text>
          {/* <Text color={"#fff"} fontSize={20} p={0}>
            xxxxxx3166
          </Text> */}
          <Link color={"#fff"} fontSize={20} p={0} onClick={handleClick}>
            lpgassociation@gmail.com
            {/* <MailLink
              title={"lpgassociation@gmail.com"}
              senderEmail={"naveen05patidar@gmail.com"}
              recipientEmail={"lpgassociation@gmail.com"}
              subject={"test"}
              body={"test"}
            /> */}
          </Link>
          <Flex gap={3} mt={2}>
            <FaTwitterSquare
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
            <FaFacebookSquare
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
            <FaLinkedin
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
            <FaInstagramSquare
              size={23}
              color="#fff"
              cursor={"pointer"}
              className="icon_hover"
            />
          </Flex>
          <Flex gap={5} mt={5}>
            <Text
              color={"#fff"}
              fontSize={20}
              p={0}
              cursor={"pointer"}
              onClick={onOpen}
              _hover={{
                fontWeight: "bold",
                transition: "",
              }}
            >
              Privacy Policy
            </Text>
            <Text
              color={"#fff"}
              fontSize={20}
              p={0}
              cursor={"pointer"}
              onClick={onPopOpen}
              _hover={{
                fontWeight: "bold",
                transition: "all .5s ease-in",
              }}
            >
              Refund Policy
            </Text>
          </Flex>
          <Flex
            gap={5}
            mt={5}
            flexDirection={["column", "column", "column", "row"]}
            _hover={{
              border: "1px solid brand.btn",
            }}
          >
            <Image
              src={images.googleplay}
              h={"50px"}
              w={"auto"}
              objectFit={"contain"}
              cursor={"pointer"}
            />
            <Image
              src={images.appstore}
              h={"50px"}
              w={"auto"}
              cursor={"pointer"}
              objectFit={"contain"}
            />
          </Flex>
        </Stack>
      </Flex>
      <Stack spacing={0} maxW={"500px"} w={"100%"} p={0}>
        <Text
          textAlign={"center"}
          color={"#fff"}
          fontSize={20}
          fontWeight={"bold"}
        >
          Contact Us
        </Text>
        <InputBox
          value={objData?.name}
          onChange={(e) => {
            setObjData({ ...objData, name: e.target.value, nameError: "" });
          }}
          errorMess={objData?.nameError}
          placeholder={"Name"}
        />
        <InputBox
          value={objData?.name_org}
          onChange={(e) => {
            setObjData({
              ...objData,
              name_org: e.target.value,
              name_orgError: "",
            });
          }}
          errorMess={objData?.name_orgError}
          placeholder={"Name of the Organization"}
        />
        <InputBox
          value={objData?.email}
          onChange={(e) => {
            setObjData({ ...objData, email: e.target.value, emailError: "" });
          }}
          errorMess={objData?.emailError}
          placeholder={"Email"}
        />
        <InputBox
          value={objData?.mobile}
          onChange={(e) => {
            const input = e.target.value.replace(/[^0-9]/g, "");
            if (input?.length <= 10) {
              setObjData({
                ...objData,
                mobile: input,
                mobileError: "",
              });
            }
          }}
          errorMess={objData?.mobileError}
          placeholder={"Phone Number"}
        />
        <InputBox
          value={objData?.specs}
          onChange={(e) => {
            setObjData({ ...objData, specs: e.target.value, specsError: "" });
          }}
          errorMess={objData?.specsError}
          placeholder={"Specifications"}
        />
        <Center mt={3}>
          <CustomButton
            bg={"brand.btn"}
            title={"Submit"}
            onClick={contact_us}
          />
        </Center>
      </Stack>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={" TLDA Privacy Policy"}
      >
        {privacy  && (
        <div dangerouslySetInnerHTML={{ __html: privacy }} />
      )}
        {/* {privacy?.map((v, i) => (
          <React.Fragment key={i}>
            <Text fontSize={18} fontWeight={600} color={"brand.text"} mt={3}>
              {v?.Title}
            </Text>
            <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
              {v?.Description}
            </Text>
          </React.Fragment>
        ))} */}
        {/* <Text fontSize={18} fontWeight={600} color={"brand.text"} mt={3}>
          INFORMATION COLLECTION AND USE BY tlda.org
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org collects information about users such as Name, Email address,
          Phone numbers etc. This information is never presented or displayed to
          other users on tlda.org site and never shared with any third party. It
          is solely used for authenticating users to use the services provided
          by tlda.org. Other Information such as address and credit card are
          solely used for making payment to tlda.org when users recharge their
          account. The credit card numbers may be encrypted and never shared
          with any other party.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org also logs some identifiable and some
          non-personally-identifiable information including IP address,
          preferences, aggregate user data, and browser type, from users and
          visitors to the site. This data is used to manage the website, track
          usage and improve the website services. This
          non-personally-identifiable information may be shared with
          third-parties to provide more relevant services and advertisements to
          members. But identifiable information is never shared with third
          parties. User IP addresses are recorded for security and monitoring
          purposes.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org may also sometimes send updates and important notifications
          regarding the service to the user's email address. From time to time,
          tlda.org or a partner may sponsor a promotion, sweepstake or contest
          on tlda.org Users may be asked to provide personal information
          including name, email address or home address or to answer questions
          in order to participate. tlda.org may transfer personal information to
          certain ad partners that you have explicitly requested to receive
          information from. It will be clear at the point of collection who is
          collecting the personal information and whose privacy statement will
          apply. If the user has registered on tlda.org and used a coupon or
          gift code provided by either tlda.org or one of our partners or
          affiliate site, then the partner may contact the user to get feedback
          on the service and potentially offer other promotions on time to time
          via phone, mail or email.
        </Text>
        <Text fontSize={18} fontWeight={600} color={"brand.text"} mt={3}>
          USE OF COOKIES
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org may use cookies for purposes such as but not limited to store
          visitors' preferences and to record session information. You may be
          able to configure your browser to accept or reject all or some
          cookies, or notify you when a cookie is set -- each browser is
          different, so check the "Help" menu of your browser to learn how to
          change your cookie preferences -- however, you should enable cookies
          from tlda.org in order to use most functions on the site. Please note
          that tlda.org allows 3rd party advertisers that are presenting
          advertisements on some of our pages to set and access their cookies on
          your computer. Advertisers' use of cookies is subject to their own
          privacy policies, not the tlda.org Privacy Policy.
        </Text> */}
      </CustomModal>
      <CustomModal
        isOpen={isPopOpen}
        onClose={onPopClose}
        title={"TLDA Refund Policy"}
      >
        {/* <Text fontSize={18} fontWeight={600} color={"brand.text"} mt={3}>
          Thank you for choosing tlda.org. Please read this policy carefully to
          understand our practices regarding refunds.
        </Text> */}
        {/* {refund.map((v, i) => (
          <React.Fragment key={i}>
            <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
              {v?.Title}
            </Text>
            <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
              {v?.Description}
            </Text>
          </React.Fragment>
        ))} */}
        {refund  && (
        <div dangerouslySetInnerHTML={{ __html: refund }} />
      )}

        {/* <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          1. Refund Eligibility
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          1.1. Service-Specific Terms: Refund eligibility may vary depending on
          the specific service or product you have purchased.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          1.2. Timeframe: Refund requests must be submitted within 2 days from
          the date of purchase of our services.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          2. Non-Refundable Services
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          2.1. Customized Services: Services that involve customization or
          personalization may be non-refundable once the customization process
          has begun.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          3. Refund Process
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          3.1. Submission of Refund Request: To request a refund, please contact
          our customer support team at support@tlda.com. Clearly state the
          reason for your refund request and provide relevant details.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          3.2. Review and Approval: Each refund request will be reviewed on a
          case-by-case basis. We reserve the right to approve or deny refund
          requests based on the specific circumstances. If our services are used
          fully or partially then no Refund will be issued.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          3.3. Refund Method: Approved refunds will be processed using the
          original payment method. Please allow 30 days for the refund to be
          reflected in your account.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          4. Exceptions
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          4.1. Force Majeure: We are not liable for refunds in cases of force
          majeure events, including but not limited to natural disasters, acts
          of war, or other unforeseeable circumstances beyond our control.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          5. Contact Us
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          If you have any questions, concerns, or requests regarding our Refund
          Policy, please contact us at <b>support@tlda.com</b> By using our
          services, you agree to abide by the terms outlined in this Refund
          Policy. Thank you for choosing TLDA!
        </Text> */}
      </CustomModal>
    </Flex>
  );
};

export default Footer;

{
  /* <Text fontSize={18} fontWeight={600} color={"brand.text"} mt={3}>
          INFORMATION COLLECTION AND USE BY tlda.org
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org collects information about users such as Name, Email address,
          Phone numbers etc. This information is never presented or displayed to
          other users on tlda.org site and never shared with any third party. It
          is solely used for authenticating users to use the services provided
          by tlda.org. Other Information such as address and credit card are
          solely used for making payment to tlda.org when users recharge their
          account. The credit card numbers may be encrypted and never shared
          with any other party.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org also logs some identifiable and some
          non-personally-identifiable information including IP address,
          preferences, aggregate user data, and browser type, from users and
          visitors to the site. This data is used to manage the website, track
          usage and improve the website services. This
          non-personally-identifiable information may be shared with
          third-parties to provide more relevant services and advertisements to
          members. But identifiable information is never shared with third
          parties. User IP addresses are recorded for security and monitoring
          purposes.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org may also sometimes send updates and important notifications
          regarding the service to the user's email address. From time to time,
          tlda.org or a partner may sponsor a promotion, sweepstake or contest
          on tlda.org Users may be asked to provide personal information
          including name, email address or home address or to answer questions
          in order to participate. tlda.org may transfer personal information to
          certain ad partners that you have explicitly requested to receive
          information from. It will be clear at the point of collection who is
          collecting the personal information and whose privacy statement will
          apply. If the user has registered on tlda.org and used a coupon or
          gift code provided by either tlda.org or one of our partners or
          affiliate site, then the partner may contact the user to get feedback
          on the service and potentially offer other promotions on time to time
          via phone, mail or email.
        </Text>
        <Text fontSize={18} fontWeight={600} color={"brand.text"} mt={3}>
          USE OF COOKIES
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={2}>
          tlda.org may use cookies for purposes such as but not limited to store
          visitors' preferences and to record session information. You may be
          able to configure your browser to accept or reject all or some
          cookies, or notify you when a cookie is set -- each browser is
          different, so check the "Help" menu of your browser to learn how to
          change your cookie preferences -- however, you should enable cookies
          from tlda.org in order to use most functions on the site. Please note
          that tlda.org allows 3rd party advertisers that are presenting
          advertisements on some of our pages to set and access their cookies on
          your computer. Advertisers' use of cookies is subject to their own
          privacy policies, not the tlda.org Privacy Policy.
        </Text> */
}

{
  /* 
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          1. Refund Eligibility
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          1.1. Service-Specific Terms: Refund eligibility may vary depending on
          the specific service or product you have purchased.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          1.2. Timeframe: Refund requests must be submitted within 2 days from
          the date of purchase of our services.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          2. Non-Refundable Services
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          2.1. Customized Services: Services that involve customization or
          personalization may be non-refundable once the customization process
          has begun.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          3. Refund Process
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          3.1. Submission of Refund Request: To request a refund, please contact
          our customer support team at support@tlda.com. Clearly state the
          reason for your refund request and provide relevant details.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          3.2. Review and Approval: Each refund request will be reviewed on a
          case-by-case basis. We reserve the right to approve or deny refund
          requests based on the specific circumstances. If our services are used
          fully or partially then no Refund will be issued.
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          3.3. Refund Method: Approved refunds will be processed using the
          original payment method. Please allow 30 days for the refund to be
          reflected in your account.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          4. Exceptions
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          4.1. Force Majeure: We are not liable for refunds in cases of force
          majeure events, including but not limited to natural disasters, acts
          of war, or other unforeseeable circumstances beyond our control.
        </Text>
        <Text fontSize={16} fontWeight={600} color={"brand.black"} mt={3}>
          5. Contact Us
        </Text>
        <Text fontSize={16} fontWeight={400} color={"brand.black"} mt={1}>
          If you have any questions, concerns, or requests regarding our Refund
          Policy, please contact us at <b>support@tlda.com</b> By using our
          services, you agree to abide by the terms outlined in this Refund
          Policy. Thank you for choosing TLDA!
        </Text> */
}
