import { Center, Container, Flex, Stack, Td, Text, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import BreadCrumName from "../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Loader } from "../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import ShowMoreText, { Table1 } from "../../../../components/Compnent";
import StatusComp from "../../../../components/StatusComp";

const UserServices = () => {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState("");
  const { notification_list, notification_loading } = useSelector(
    (state) => state.allData
  );
  const [loading, setLoading] = useState(false);
  const { type } = useSelector((state) => state.otherData);
  const [list, setList] = useState([
    {
      ID: 1,
      des: "lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa ",
      Title: "GST and TDS Filling",
      date_time: "12/12/2024 06:12 PM",
      type: "Approved",
      status: "On Working",
    },
    {
      ID: 2,
      des: "lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa lorem sdasdads dasdasdsa ",
      Title: "GST and TDS Filling",
      date_time: "12/12/2024 06:12 PM",
      type: "Pending",
      status: "",
      reason: "Rejected by Admin due to non availability",
    },
  ]);

  console.log( list);

  const { state } = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (state.subCat_id) {
      setLoading(true);
      const body = new FormData();
      body.append("action", "listservices");
      body.append("category_id", 4);
      body.append("sub_category_id", state?.subCat_id);
      body.append("user_id", user?.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      console.log("res ======", res);
      setLoading(false);
      if (res.success) {
        setList(res.data);
      } else if (!res.success) {
        setList([]);
      }
    }
  };

  const update_count = async (id) => {};

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Services"}
        titlelist={[
          { name: "Home", to: "/" },
          // type == 1&&{ name: "Our Offering", to: "/home" },
          { name: "Get Advice", to: -2 },
          { name: state.name, to: -1 },
        ]}
      />

      <Container maxW={"container.lg"} py={5}>
        {loading ? (
          <Loader />
        ) : (
          list?.map((item, index) => (
            <Stack
              key={index}
              borderRadius={10}
              //   bg={item?.status == "0" ? "#f2f3f5" : ""}
              py={2}
              px={5}
              mt={[3, 5]}
              cursor={"pointer"}
              onClick={() => {
                // navigate("/notification-detail", { state: item });
                // update_count(item?.ID);
              }}
              position={"relative"}
              border={`1px solid ${
                item?.status == "0"
                  ? "orange"
                  : item?.status == "1"
                  ? "green"
                  : item?.status == "2"
                  ? "red"
                  : "orange"
              }`}
            >
              {/* {item?.status &&} */}
              <StatusComp status={item?.status} />

              <Flex justify={"space-between"}>
                <Text
                  color={"#2b3478"}
                  fontSize={[16, 18, 20]}
                  fontWeight={item?.status == "1" ? 500 : 800}
                >
                  {item?.Description}
                </Text>
                {/* <Text
                  display={"flex"}
                  color={
                    item?.type == "Approved" ? "brand.primary" : "brand.btn"
                  }
                  gap={4}
                >
                  {item?.type}
                </Text> */}
              </Flex>

              <Text color={"brand.text1"}>{item.Description}</Text>

              {item.status == 1 && item?.payment_status == 0 && (
                <Flex
                w={"full"}
                justifyContent={["flex-start", "flex-start"]}
                  alignItems={["flex-start", "center"]}
                  flexDirection={["column", "row"]}
                  gap={[2, 0]}
                >
                  <CustomButton title={"Pay Now"} bg={"brand.primary"} />
                </Flex>
              )}

              <Flex
                 w={"full"}
                 direction={"flex"}
                 justifyContent={["flex-start", "flex-end"]}
                alignItems={["flex-start", "flex-end"]}
                flexDirection={["column", "row"]}
                gap={[2, 0]}
              >
                <Text
                  // w={"fit-content"}
                  display={"flex"}
                  // textAlign={"right"}
                  flex={1}
                  color={"brand.primary"}
                  gap={4}
                  onClick={() => {
                    if (showTable == index + 1) {
                      setShowTable("");
                    } else {
                      setShowTable(index + 1);
                    }
                  }}
                >
                  {showTable == index + 1 ? "Less Status" : "Show Status"}
                </Text>
{/* 
                <Text
                  // w={"100%"}
                  // flex={1}
                  textAlign={"right"}
                  color={"brand.text1"}
                  fontSize={[14, 15, 16]}
                  fontWeight={500}
                >
                  {item.date_time || "12-jan-2024 02:04 PM"}
                </Text> */}
              </Flex>

              {showTable == index + 1 && (
                <Table1
                  headData={[
                    "Sl No.",
                    "Provider Name",
                    "Status",
                    "Request Date",
                    "Status Update Date",
                  ]}
                >
                  {Array(5)
                    .fill()
                    ?.map((v, i) => (
                      <Tr key={i}>
                        <Td>
                          <Center>{i + 1}</Center>
                        </Td>
                        <Td>
                          <Center>{"Naveen Patidar"}</Center>
                        </Td>
                        <Td>
                          <Center>{"Auto Rejected"}</Center>
                        </Td>
                        <Td>
                          <Center>{"15/05/2024 04:00 PM"}</Center>
                        </Td>
                        <Td>
                          <Center>{"18/05/2024 04:00 PM"}</Center>
                        </Td>
                      </Tr>
                    ))}
                </Table1>
              )}
            </Stack>
          ))
        )}
      </Container>
    </Container>
  );
};

export default UserServices;
