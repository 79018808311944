import {
  Box,
  Center,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import BreadCrumName from "../../../../../components/BreadCrumName";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectImage,
} from "../../../../../utils/LayoutUtils";
import { ListDataShow } from "../../../../../components/Compnent";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ImageModal from "../../../../../components/ImageModal";
import { calculateExperience } from "../../../../../utils/utils";

const MechanicSuplier = () => {
  const [data, setData] = useState({});

  const [selcted, setSelcted] = useState("1");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [alreadyFilledForm, setAlreadyFilledForm] = useState("filled");
  const { type } = useSelector((state) => state.otherData);
  const [dataForm, setDataForm] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState("");

  console.log("dataForm ===", dataForm);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const body = new FormData();
      body.append("action", "check_supplier");
      body.append("category_id", state?.ID);
      body.append("service_user_id", user.id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      if (res.success) {
        setAlreadyFilledForm("filled");
        setDataForm(res.data);
      } else {
        setAlreadyFilledForm("notfilled");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const { state } = useLocation();

  console.log("state ===", state);
  const today = new Date().toISOString().split("T")[0];

  // serviceupdate(id, category_id, service_user_id, from_date, to_date address,experience, charges  status)

  const update_data = async () => {
    try {
      if (
        !data.charges ||
        data.charges < 0 ||
        !data.fdate ||
        data.fdate?.length != 10 ||
        !data.tdate ||
        data.tdate?.length != 10 ||
        !data.address ||
        data.address?.length < 50 ||
        data.address?.length > 200 ||
        !data.experience
      ) {
        setData({
          ...data,
          chargesMess: !data.charges
            ? "Charges is required"
            : data.charges < 0
            ? "Charges always greater then 1"
            : "",
          fdateMess: !data.fdate
            ? "From Date is required"
            : data.fdate?.length != 10
            ? "Please Enter Valid From Date"
            : "",
          tdateMess: !data.tdate
            ? "To Date is required"
            : data.tdate?.length != 10
            ? "Please Enter Valid To Date"
            : "",
          addressMess: !data.address
            ? "Address is required"
            : data.address?.length < 50
            ? "Address always greater then be 50 charector"
            : data.address?.length > 200
            ? "Address must be less then 200 charector"
            : "",
          experienceMess: !data.experience ? "Experience  is required" : "",
        });
        return;
      }
      setLoading(true);

      const body = new FormData();
      body.append("action", "serviceupdate");
      body.append("category_id", state?.ID);
      body.append("service_user_id", user.id);
      body.append("id", data.id);
      body.append("from_date", data?.fdate);
      body.append("to_date", data?.tdate);
      body.append("address", data?.address);
      body.append("status", selcted);
      body.append("certificate", data?.file);
      body.append("experience", data?.experience);
      body.append("charges", data?.charges);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setAlreadyFilledForm("filled");
        setData({});
        fetchData();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
  };

  // serviceadd(category_id, service_user_id, from_date, to_date, address, status, experience, charges, certificate)
  const add_mechanics = async () => {
    try {
      if (
        !data.charges ||
        data.charges < 0 ||
        !data.fdate ||
        data.fdate?.length != 10 ||
        !data.tdate ||
        data.tdate?.length != 10 ||
        !data.address ||
        data.address?.length < 50 ||
        data.address?.length > 200 ||
        !data.experience ||
        !data.file.name
      ) {
        setData({
          ...data,
          chargesMess: !data.charges
            ? "Charges is required"
            : data.charges < 0
            ? "Charges always greater then 1"
            : "",
          fdateMess: !data.fdate
            ? "From Date is required"
            : data.fdate?.length != 10
            ? "Please Enter Valid From Date"
            : "",
          tdateMess: !data.tdate
            ? "To Date is required"
            : data.tdate?.length != 10
            ? "Please Enter Valid To Date"
            : "",
          addressMess: !data.address
            ? "Address is required"
            : data.address?.length < 50
            ? "Address always greater then be 50 charector"
            : data.address?.length > 200
            ? "Address must be less then 200 charector"
            : "",
          experienceMess: !data.experience ? "Experience is required" : "",
          fileMess: !data.file?.name ? "Certificate is required" : "",
        });
        return;
      }
      setLoading(true);
      const body = new FormData();
      body.append("action", "serviceadd");
      body.append("category_id", state?.ID);
      body.append("service_user_id", user.id);
      body.append("from_date", data?.fdate);
      body.append("to_date", data?.tdate);
      body.append("address", data?.address);
      body.append("status", selcted);
      body.append("certificate", data?.file);
      body.append("experience", data?.experience);
      body.append("charges", data?.charges);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setAlreadyFilledForm(true);
        setData({});
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formattedExperience = useMemo(() => {
    return calculateExperience(dataForm?.Experience_Month || 0);
  }, [dataForm?.Experience_Month]);

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={"Mechanic/MI"}
          titlelist={[
            { name: "Home", to: "/" },
            type == 1 && { name: "Our Offering", to: "/home" },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            bg={"brand.btn"}
            title={"Notifications"}
            onClick={() => {}}
          />
        </Flex>
      </Flex>
      {alreadyFilledForm == "filled" ? (
        <Container maxW={"container.sm"} p={4}>
          {loading1 ? (
            <Loader />
          ) : (
            <Box
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
              border={"1px solid"}
              borderColor={ dataForm?.Status == "1"
                ? "brand.primary"
                : dataForm?.Status == "2"
                ? "brand.btn"
                : dataForm?.Status == "3"
                ? "#FFD700"
                : ""}
            >
              <Flex flexDirection={"column"} w={"100%"}>
                <Text fontWeight={"bold"} fontSize={20} textAlign={"center"} pb={3}>Profile</Text>
                <ListDataShow title={"Charges"} value={<>Rs. {dataForm?.Charges} /day</>} />
                <ListDataShow
                  title={"From Date"}
                  value={moment(dataForm?.From_date).format("DD-MMM-YYYY")}
                />
                <ListDataShow
                  title={"To Date"}
                  value={moment(dataForm?.To_date).format("DD-MMM-YYYY")}
                />
                <ListDataShow
                  title={"Status"}
                  color={
                    dataForm?.Status == "1"
                      ? "brand.primary"
                      : dataForm?.Status == "2"
                      ? "brand.btn"
                      : dataForm?.Status == "3"
                      ? "#FFD700"
                      : ""
                  }
                  value={
                    dataForm?.Status == "1"
                      ? "Active"
                      : dataForm?.Status == "2"
                      ? "In-Active"
                      : dataForm?.Status == "3"
                      ? "Engage"
                      : ""
                  }
                />
                <ListDataShow title={"Address"} value={dataForm?.Address} />
                <ListDataShow
                  title={"Exeperience"}
                  value={formattedExperience}
                />
              </Flex>
              <Flex
                mt={4}
                w={["full", "40%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"2"}
              >
                <CustomButton
                  w="full"
                  title={"View"}
                  bg={"brand.primary"}
                  onClick={() => {
                    onOpen();
                  }}
                />
                <CustomButton
                  w="full"
                  title={"Edit"}
                  bg={"brand.btn"}
                  onClick={() => {
                    setSelcted(dataForm?.Status);
                    setData({
                      ...data,
                      id: dataForm?.ID,
                      fdate: moment(dataForm?.From_date).format("YYYY-MM-DD"),
                      tdate: moment(dataForm?.To_date).format("YYYY-MM-DD"),
                      address: dataForm?.Address,
                      experience: dataForm?.Experience_Month,
                      charges: dataForm?.Charges,
                    });
                    setAlreadyFilledForm("Edit");
                  }}
                />
              </Flex>
            </Box>
            // ))
          )}
        </Container>
      ) : (
        <Container maxW={"container.sm"} p={4}>
          <InputBox
            label={"Charges (per Day)"}
            placeholder={"Charges "}
            value={data.charges}
            onChange={(v) => {
              const inputValue = v.target.value;
              if (
                (inputValue?.length <= 15 &&
                  /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(inputValue)) ||
                inputValue == 0 ||
                !data.charges
              ) {
                setData({
                  ...data,
                  charges: /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(inputValue)
                    ? inputValue
                    : "",
                  chargesMess: "",
                });
              }
            }}
            errorMess={data.chargesMess}
          />
          <InputBox
            //   max={eighteenYearsAgo2}
            label={"From Date"}
            placeholder={"From Date"}
            type={"date"}
            value={data.fdate}
            onChange={(v) => {
              setData({
                ...data,
                fdate: v.target.value,
                fdateMess: "",
              });
            }}
            errorMess={data.fdateMess}
            min={today}
          />
          <InputBox
            //   max={eighteenYearsAgo2}
            label={"To Date"}
            placeholder={"To Date"}
            type={"date"}
            value={data.tdate}
            onChange={(v) => {
              setData({
                ...data,
                tdate: v.target.value,
                tdateMess: "",
              });
            }}
            errorMess={data.tdateMess}
            min={data.fdate || today}
          />
          <InputBox
            label={"Experience (in Months)"}
            placeholder={"Experience"}
            value={data?.experience || ""}
            onChange={(v) => {
              const input = v.target.value.replace(/\D/g, "");
              if (input?.length < 4) {
                setData({
                  ...data,
                  experience: input,
                  experienceMess: "",
                });
              }
            }}
            errorMess={data.experienceMess}
          />
          <InputBox
            area={true}
            label={"Address*"}
            placeholder={"Address*"}
            value={data.address}
            onChange={(v) => {
              const input = v.target.value.replace(/\s\s+/g, " ");
              setData({
                ...data,
                address: input,
                addressMess: "",
              });
            }}
            errorMess={data.addressMess}
          />
          <RadioGroup mt={4} value={selcted} onChange={setSelcted}>
            <Stack spacing={5} direction={["column", "row"]}>
              <Text>Status</Text>
              <Radio colorScheme="blue" opacity={"1"} value="1">
                Active
              </Radio>
              <Radio colorScheme="blue" opacity={"1"} value="2">
                In-Active
              </Radio>
              <Radio colorScheme="blue" opacity={"1"} value="3">
                Engage
              </Radio>
            </Stack>
          </RadioGroup>
          <SelectImage
            label={"Upload Document"}
            value={data.file?.name}
            filename={data.file?.name}
            onChange={(v) => {
              setData({ ...data, file: v.target.files[0], fileMess: "" });
            }}
            errorMess={data.fileMess}
          />
          <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
            <CustomButton
              bg={"#2b3478"}
              title={alreadyFilledForm == "Edit" ? "Update" : "Submit"}
              mt={5}
              onClick={
                alreadyFilledForm == "Edit" ? update_data : add_mechanics
              }
              loading={loading}
            />
            {alreadyFilledForm == "Edit" && (
              <CustomButton
                mt={5}
                title={"Cancel"}
                bg={"brand.btn"}
                onClick={() => {
                  setAlreadyFilledForm("filled");
                  setData({});
                }}
              />
            )}
          </Flex>
        </Container>
      )}

      <ImageModal
        url={dataForm?.Certificate}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Container>
  );
};

export default MechanicSuplier;

// Supplier (Service-provider)
// 1. supplierlist(service_user_id)
// 2. serviceadd(category_id, service_user_id, from_date, to_date, address, status, certificate)
// 3. serviceupdate(id, category_id, service_user_id, from_date, to_date address, status)
// 4. status(id, service_user_id, status)
// 5. supplierRequest(id, user_id, status)
// 6. complete_status(id, user_id)


// Member side 
// 1. memberlist(category_id)
// 2. Member_request(category_id, user_id, service_user_id, Mechanic_id, from_date, to_date, address, certificate)
// 3. MemberRequestlist(service_user_id)
// 4. feedback(service_user_id, mechanic_id, feedback, rating)
// 5. feedback_list
