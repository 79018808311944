import {
  Center,
  Flex,
  GridItem,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

const MotionText = motion(Text);

export const DotHeading = ({ title }) => {
  return (
    <Text
      display={"flex"}
      flexDirection={"row"}
      gap={2}
      alignItems={"center"}
      fontSize={["14", "16", "18", "20"]}
      color={"#5B686A"}
      fontWeight={600}
      mt={2}
    >
      <GoDotFill />
      <Text>{title}</Text>
    </Text>
  );
};

export const HeadingMain = ({ title, ref }) => {
  return (
    <Text
      fontSize={["21", "24", "27", "30"]}
      fontWeight={600}
      textAlign={"center"}
      ref={ref}
      pt={[2, 3, 4, 5]}
      pb={[2, 3, 4, 5]}
      color={"black"}
    >
      {title}
    </Text>
  );
};

export const HeadingSub = ({ title }) => {
  return (
    <Text
      fontSize={["18", "20", "22", "24"]}
      color={"black"}
      fontWeight={200}
      mt={2}
    >
      {title}
    </Text>
  );
};

export const HeadingText = ({ title, pl }) => {
  return (
    <Text
      fontSize={["14", "16", "18", "20"]}
      color={"#5B686A"}
      textAlign={"justify"}
      fontWeight={400}
      paddingLeft={pl}
    >
      {title}
    </Text>
  );
};

export const MailLink = ({
  senderEmail,
  recipientEmail,
  subject,
  body,
  title,
}) => {
  const handleClick = () => {
    const mailtoLink = `mailto:${recipientEmail}?cc=${senderEmail}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, "_blank");
  };

  return <Link onClick={handleClick}>{title}</Link>;
};

export const HeaderItems = ({ title, onClick }) => {
  const [sameData, setSameData] = useState("");
  const hover = {
    width: "full",
    fontWeight: "bold",
    // textDecoration: "underline",
    transition: "width 2s ",
  };
  return (
    <MotionText
    cursor="pointer"
    fontSize="large"
    fontWeight={400}
    color="#fff"
    whileHover={{ scale:1.1, transition: { duration: 0.2 }, }}
    onClick={onClick}
    onMouseEnter={() => {
      setSameData(title);
    }}
    onMouseLeave={() => {
      setSameData("");
    }}
  >
    {title}
  </MotionText>
  );
};

export const DrawerItem = ({ title, onClick }) => {
  return (
    <Text
      cursor={"pointer"}
      fontSize={"large"}
      fontWeight={400}
      color={"#2b3478"}
      onClick={onClick}
      p={2}
      _hover={{
        fontWeight: "800",
      }}
    >
      {title}
    </Text>
  );
};

export const GridInputComponent = ({ content, mt = 0, p = [1, 5] }) => {
  return (
    <GridItem
      mt={mt}
      mb={mt - 1}
      w="100%"
      bg="#fff"
      borderRadius={10}
      alignItems={"center"}
      justifyContent={"flex-start"}
      display={"flex"}
      flexDirection={"row"}
      p={p}
      cursor={"pointer"}
      gap={2}
    >
      {content}
    </GridItem>
  );
};

export const ListDataShow = ({ title, value, f1 = [3, 3], f2 = [6, 12],color }) => {
  return (
    <Flex px={3} mt={1} mb={1} w={"100%"}>
      {title &&
      <Text flex={f1} color={"brand.text"} fontSize={16}>
      {title}
    </Text>}
      <Text flex={f2} color={color?color:"brand.text2"} fontWeight={color?"bold":""} fontSize={15}>
       {title &&<>:</> } {value}
      </Text>
    </Flex>
  );
};

export const ShareContent = ({ url }) => {
  return <img src={url} alt="Certificate" />;
};

export const ShowMoreText = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return (
    <div>
      <Text color={"brand.text1"}>{isExpanded ? text : truncatedText}</Text>
      {text.length > maxLength && (
        <Text
          onClick={toggleExpansion}
          // colorScheme="teal"
          variant="link"
          color={isExpanded ? "brand.btn" : "brand.primary"}
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Text>
      )}
    </div>
  );
};

export const Table1 = ({ caption, headData, children, footer }) => {
  return (
    <TableContainer>
      <Table variant="simple" w={"full"}>
        <TableCaption>{caption}</TableCaption>
        <Thead>
          <Tr>
            {headData?.map((v, i) => (
              <Th key={i} color={"brand.primary"}>
                <Center>{v}</Center>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
        {footer && <Tfoot>{footer}</Tfoot>}
      </Table>
    </TableContainer>
  );
};
// export const
