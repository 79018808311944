import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { images } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { CustomButton, InputBox } from "../../utils/LayoutUtils";
import { IoArrowBackOutline } from "react-icons/io5";
import { onLogin } from "../../utils/apis";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import store from "../../redux/store";
import { get_district_list, get_service_list, get_user_type } from "../../redux/otherSlice";

const Login = () => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(null);
  const onCheckNumber = () => {
    if (!data.number) {
      setData({
        ...data,
        numMess: !data.number ? "Number is required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("Mobile_Number", data.number);
    onLogin(body, setLoading)
      .then((res) => {
        if (res.success) {
          (res.data == 0 || res.data == 1) && setShowPass(res.data);
          (res.data == 0 || res.data == 1) &&
            localStorage.setItem("type", res.data);
          res.data == 2 && navigate("/register", { state: data.number });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };
  const onClickLogin = () => {
    if (!data.number || !data.pass) {
      setData({
        ...data,
        numMess: !data.number ? "Number is required" : "",
        passMess: !data.pass ? "Password is required" : "",
      });
      return;
    }
    const body = new FormData();
    body.append("Mobile_Number", data.number);
    body.append("password", data.pass);
    onLogin(body, setLoading)
      .then((res) => {
        if (res.success) {
          toast.success(res.msg);
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("mobile", data.number);
          localStorage.setItem("services", res.service);
          store.dispatch(get_user_type());

          navigate("/");
          // window.location.reload();
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    store.dispatch(get_district_list());
    store.dispatch(get_service_list());
  }, []);
  return (
    <Container
      // alignItems={["center", "center", "center", "center"]}
      // justifyContent={["flex-start", "flex-start", "flex-start", "flex-start"]}
      //  // minH={"100vh"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      minW="100vw"
      h={"100%"}
      // bg={"brand.white"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Container maxW={"container.lg"} pt={5}>
        <Flex alignSelf={"flex-start"} alignItems={"center"} gap={5}>
          <IoArrowBackOutline
            size={30}
            color="#2b3478"
            cursor={"pointer"}
            onClick={() => navigate(-1)}
          />
          <Text fontSize={[18, 22, 26, 30]} fontWeight={600} color={"#2b3478"}>
            Login
          </Text>
        </Flex>
      </Container>
      <Stack p={5} maxW="lg" w={"100%"} mt={["10px", 10, 20, 70]}>
        <Image
          src={images.logo}
          h={"150px"}
          w={"150px"}
          objectFit={"contain"}
          alignSelf={"center"}
        />
        <Heading
          fontSize={[16, 18, 20]}
          textAlign={"center"}
          py={2}
          fontWeight={600}
        >
          LPG Association
        </Heading>
        <Text fontSize={18} fontWeight={400} color={"brand.black"}>
          Login
        </Text>
        <InputBox
          placeholder={"Mobile Number"}
          mt={2}
          value={data.number}
          maxLength={10}
          onChange={(v) => {
            setData({
              ...data,
              number: v.target.value.trim().replace(/[^0-9 ]/g, ""),
              numMess: "",
            });
          }}
          errorMess={data.numMess}
        />
        {(showPass == 0 || showPass == 1) && (
          <InputBox
            placeholder={"Password"}
            value={data.pass}
            onChange={(v) => {
              setData({ ...data, pass: v.target.value.trim(), passMess: "" });
            }}
            errorMess={data.passMess}
            type={show ? "text" : "password"}
            RightElement={
              show ? (
                <FiEyeOff size={20} color="#000" onClick={handleClick} />
              ) : (
                <FiEye size={20} color="#000" onClick={handleClick} />
              )
            }
          />
        )}
        <Text
          fontSize={16}
          fontWeight={400}
          color={"brand.text"}
          alignSelf={"end"}
          cursor={"pointer"}
          mt={2}
          onClick={() => navigate("/reset-password")}
        >
          Forgot Password?
        </Text>
        <CustomButton
          title={"Login"}
          mt={5}
          onClick={
            showPass == 0 || showPass == 1 ? onClickLogin : onCheckNumber
          }
          loading={loading}
        />
        {/* <Text
          fontSize={16}
          fontWeight={400}
          color={"brand.text"}
          alignSelf={"center"}
          cursor={"pointer"}
          mt={2}
          onClick={() => navigate("/register")}
        >
          Create Account?
        </Text> */}
      </Stack>
    </Container>
  );
};

export default Login;
