import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { toast } from "react-toastify";
import { FaStar } from "react-icons/fa";
import CustomModal from "../../../../components/CustomModal";
import {
  GridInputComponent,
  ListDataShow,
} from "../../../../components/Compnent";
import { DateValidator2, calculateExperience } from "../../../../utils/utils";
import moment from "moment/moment";
import ImageModal from "../../../../components/ImageModal";

const Mechanic = () => {
  const { state } = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [data, setData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log("data");
  console.log("state ==", state);
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const today = moment().subtract(0, "days").format("DD/MM/YYYY");

  const get_list = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "memberlist");
      // body.append("district", user.district_id);
      body.append("category_id", state?.ID);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      console.log("res ===", res);
      setLoading(false);
      if (res.success) {
        setList(res.list);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get_list();
  }, []);

  const validate = async () => {
    const obj = {};
    // const data2 = await DateValidator2(
    //   moment(data?.fdate).format("DD/MM/YYYY"),
    //   today,
    //   day_end_date,
    //   `Invoice Date cant be below  as Day end Date ${day_end_date}`,
    //   "Invoice Date cant be a future date"
    // );
    // else if (data != true) {
    //   arr.push(...data);
    // }
    if (!data?.fdate || (data?.fdate && data?.fdate?.length != 10)) {
      obj.fdateMess = "Please Select Start date";
    }
    // else if (data2 != true) {
    //   arr.push(...data2);
    // }

    if (!data?.tdate || (data?.tdate && data?.tdate?.length != 10)) {
      obj.tdateMess = "Please Select End date";
    }

    if (
      !data?.address ||
      (data?.address && data?.address?.trim()?.length == 0)
    ) {
      obj.addressMess = "Please Enter Address";
    } else if (data?.address?.trim()?.length < 8) {
      obj.addressMess = "Address Should be min 8 charactors";
    }
    if (!data?.status || (data?.status && data?.status?.length == 0)) {
      obj.statusMess = "Please Select Status Type";
    }
    if (
      !data?.certificate?.name ||
      (data?.certificate?.name && data?.certificate?.name?.length == 0)
    ) {
      obj.certificateMess = "Please Select Certificate";
    }
    if (
      !data?.aadhar?.name ||
      (data?.aadhar?.name && data?.aadhar?.name?.length == 0)
    ) {
      obj.aadharMess = "Please Select Aadhar";
    }
    setData({
      ...data,
      ...obj,
    });
  };

  const on_assign = async () => {
    if (
      !data.fdate ||
      data.fdate?.length != 10 ||
      !data.tdate ||
      data.tdate?.length != 10 ||
      !data.address ||
      data.address?.length < 50 ||
      data.address?.length > 200
    ) {
      setData({
        ...data,
        fdateMess: !data.fdate
          ? "From Date is required"
          : data.fdate?.length != 10
          ? "Please Enter Valid From Date"
          : "",
        tdateMess: !data.tdate
          ? "To Date is required"
          : data.tdate?.length != 10
          ? "Please Enter Valid To Date"
          : "",
        addressMess: !data.address
          ? "Address is required"
          : data.address?.length < 50
          ? "Address always greater then be 50 charector"
          : data.address?.length > 200
          ? "Address must be less then 200 charector"
          : "",
      });
      return;
    }
    try {
      // Member_request(category_id, user_id, service_user_id, Mechanic_id, from_date, to_date, address, certificate)
      setLoading(true);
      const body = new FormData();
      body.append("action", "Member_request");
      body.append("category_id", state?.id); //Done
      body.append("user_id", user?.id); // Done
      body.append("service_user_id", data?.serv_id);
      body.append("Mechanic_id", data?.id);
      body.append("from_date", data?.fdate);
      body.append("to_date", data?.tdate);
      body.append("address", data?.address);
      body.append("certificate", data?.file);
      // body.append("list", data?.list_id);
      // body.append("status", data?.status);
      // body.append("user_id", user?.id);
      // body.append("aadhar_upload", data?.aadhar);
      // body.append("district", data.district);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      setLoading(false);
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setData({});
        toast.success(res.message);
        onClose();
        get_list();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const formattedExperience = useMemo(() => {
  //   return calculateExperience(list || 0);
  // }, [list]);
  console.log(" ===", list);
  const processedData = useMemo(() => {
    return list.map((item) => ({
      ...item,
      formattedExperience: calculateExperience(item?.Experience_Month || 0),
    }));
  }, [list]);
  console.log(" ===", processedData);

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={"Mechanic/MI"}
          titlelist={[{ name: "Home", to: "/" }]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            bg={"brand.primary"}
            title={"Orders"}
            onClick={() => {}}
          />
        </Flex>
      </Flex>
      <Container maxW={"container.sm"} p={[1, 4]}>
        {loading ? (
          <Loader />
        ) : processedData.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          <Flex flexDir={"column"} gap={[2, 3]} justifyContent={"center"}>
            {processedData?.map((v, index) => (
              // <Flex
              //   key={index}
              //   w={"full"}
              //   bg="#fff"
              //   shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              //   borderRadius={10}
              //   p={5}
              //   gap={5}
              // >
              //   <Image
              //     src={item.Photo}
              //     h={"100%"}
              //     w={"70px"}
              //     maxH={"70px"}
              //     objectFit={"cover"}
              //     borderRadius={"50%"}
              //   />
              //   <Stack flex={1}>
              //     <Text color={"brand.text"} fontSize={18} fontWeight={600}>
              //       {item.Name}
              //     </Text>
              //     <Flex gap={2}>
              //       <FaStar
              //         color={item?.Rating > 0 ? "#FFD700" : "gray"}
              //         size={20}
              //       />
              //       <FaStar
              //         color={item?.Rating > 1 ? "#FFD700" : "gray"}
              //         size={20}
              //       />
              //       <FaStar
              //         color={item?.Rating > 2 ? "#FFD700" : "gray"}
              //         size={20}
              //       />
              //       <FaStar
              //         color={item?.Rating > 3 ? "#FFD700" : "gray"}
              //         size={20}
              //       />
              //       <FaStar
              //         color={item?.Rating > 4 ? "#FFD700" : "gray"}
              //         size={20}
              //       />
              //     </Flex>
              //   </Stack>

              //   <CustomButton
              //     title={"Assign"}
              //     onClick={() => {
              //       setName(item.Name);
              //       setData({
              //         ...data,
              //         category_id: item.Category_id,
              //         district: item?.District,
              //         list_id: item?.ID,
              //       });
              //       onOpen();
              //     }}
              //   />
              // </Flex>
              <Box
                key={index}
                bg={"#fff"}
                p={5}
                mt={2}
                cursor={"pointer"}
                borderRadius={10}
                display={"flex"}
                flexDirection={["column", "column"]}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={[2, "5%"]}
                border={"1px solid"}
                borderColor={
                  v?.Status == "1"
                    ? "brand.primary"
                    : v?.Status == "2"
                    ? "brand.btn"
                    : v?.Status == "3"
                    ? "#FFD700"
                    : ""
                }
              >
                <Flex flexDirection={"column"} w={"100%"}>
                  <ListDataShow
                    title={"Charges"}
                    value={<>Rs. {v?.Charges || 0} /day</>}
                  />
                  <ListDataShow
                    title={"From Date"}
                    value={moment(v?.From_date).format("DD-MMM-YYYY")}
                  />
                  <ListDataShow
                    title={"To Date"}
                    value={moment(v?.To_date).format("DD-MMM-YYYY")}
                  />
                  <ListDataShow title={"Address"} value={v?.Address} />
                  <ListDataShow
                    title={"Exeperience"}
                    value={v?.formattedExperience}
                  />
                </Flex>
                <Flex
                  mt={4}
                  w={["full", "40%"]}
                  alignItems={"flex-end"}
                  flexDirection={["row", "row"]}
                  justifyContent={"flex-end"}
                  gap={"2"}
                >
                  <CustomButton
                    w="full"
                    title={"View"}
                    bg={"brand.primary"}
                    onClick={() => {
                      onOpen1();
                      setUrl(v?.Certificate);
                    }}
                  />
                  <CustomButton
                    w="full"
                    title={"Get"}
                    bg={"brand.btn"}
                    onClick={() => {
                      onOpen();
                      setData({
                        ...data,
                        serv_id: v?.service_user_id,
                        id: v?.ID,
                      });
                    }}
                  />
                </Flex>
              </Box>
            ))}
          </Flex>
        )}
      </Container>
      <CustomModal
        onCancel={() => {
          setData({});
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={`Get Mechanic`}
      >
        <Container maxW={"container.sm"} p={4}>
          <InputBox
            label={"From Date"}
            placeholder={"From Date"}
            type={"date"}
            value={data.fdate}
            onChange={(e) => {
              setData({ ...data, fdate: e.target.value, fdateMess: "" });
            }}
            errorMess={data?.fdateMess}
          />
          <InputBox
            label={"To Date"}
            placeholder={"To Date"}
            type={"date"}
            value={data.tdate}
            onChange={(e) => {
              if (e.target.value?.length <= 10) {
                setData({ ...data, tdate: e.target.value, tdateMess: "" });
              }
            }}
            errorMess={data?.tdateMess}
          />
          <InputBox
            label={"Address"}
            placeholder={"Address"}
            value={data.address}
            onChange={(e) => {
              setData({
                ...data,
                address: e.target.value,
                addressMess: "",
              });
            }}
            errorMess={data?.addressMess}
          />
          <SelectImage
            label={"Document"}
            filename={data?.file?.name}
            onChange={(e) => {
              setData({
                ...data,
                file: e.target.files[0],
                fileMess: "",
              });
            }}
            errorMess={data?.fileMess}
          />
        </Container>
       
        <Center mt={5}>
          <CustomButton
            title={"Submit"}
            onClick={on_assign}
            loading={loading}
          />
        </Center>
      </CustomModal>
      <ImageModal url={url} isOpen={isOpen1} onClose={onClose1} />
    </Container>
  );
};

export default Mechanic;


 {/* <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={[2, 4, 4, 4]}
        >
          <GridInputComponent
            content={
              <InputBox
                label={"From Date"}
                placeholder={"From Date"}
                type={"date"}
                value={data.fdate}
                onChange={(e) => {
                  setData({ ...data, fdate: e.target.value, fdateMess: "" });
                }}
                errorMess={data?.fdateMess}
              />
            }
          />
          <GridInputComponent
            content={
              <InputBox
                label={"To Date"}
                placeholder={"To Date"}
                type={"date"}
                value={data.tdate}
                onChange={(e) => {
                  if (e.target.value?.length <= 10) {
                    setData({ ...data, tdate: e.target.value, tdateMess: "" });
                  }
                }}
                errorMess={data?.tdateMess}
              />
            }
          />
          <GridInputComponent
            content={
              <InputBox
                label={"Address"}
                placeholder={"Address"}
                value={data.address}
                onChange={(e) => {
                  setData({
                    ...data,
                    address: e.target.value,
                    addressMess: "",
                  });
                }}
                errorMess={data?.addressMess}
              />
            }
          />
         
          <GridInputComponent
            content={
              <SelectImage
                label={"Document"}
                filename={data?.file?.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    file: e.target.files[0],
                    fileMess: "",
                  });
                }}
                errorMess={data?.fileMess}
              />
            }
          />
        </Grid> */}

{
  /* <GridInputComponent
            content={
              <RadioGroup
                value={data.status}
                onChange={(e) => {
                  setData({ ...data, status: e, statusMess: "" });
                }}
                errorMess={data?.statusMess}
              >
                <Stack spacing={5} direction="row">
                  <Flex color={"brand.primary"}> Status</Flex>
                  <Radio colorScheme="purple" value="1">
                    Yes
                  </Radio>
                  <Radio colorScheme="purple" value="2">
                    No
                  </Radio>
                </Stack>
                {data?.statusMess && (
                  <Text mt={2} color={"#f00"} fontSize={14}>
                    {data?.statusMess}
                  </Text>
                )}
              </RadioGroup>
            }
          /> */
}
{
  /* <GridInputComponent
            content={
              <SelectImage
                label={"Certificates"}
                filename={data?.certificate?.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    certificate: e.target.files[0],
                    certificateMess: "",
                  });
                }}
                errorMess={data?.certificateMess}
              />
            }
          /> */
}
