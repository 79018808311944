import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectBox,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import CustomModal from "../../../../components/CustomModal";
import ImageModal from "../../../../components/ImageModal";
import { useSelector } from "react-redux";
import { HeadingText, ListDataShow } from "../../../../components/Compnent";

const AddAdvice = () => {
  const { state } = useLocation();
  const [url, setUrl] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [serviceData, setServiceData] = useState({});
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const mobileNumber = JSON.parse(localStorage.getItem("mobile"));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileData, setFileData] = useState([]);
  // const { isOpen1, onOpen1, onClose1 } = useDisclosure();
  const navigate = useNavigate();
  const { type } = useSelector((state) => state.otherData);
  const [legal_advice_id, setLegal_advice_id] = useState("");
  // console.log("dataList =====", dataList);
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const onAddAdvice = async () => {
    if (
      !data.title ||
      !data.desc ||
      (!data?.file?.name && data?.type == "create")
    ) {
      setData({
        ...data,
        titleMess: !data.title ? "Title is required" : "",
        descMess: !data.desc ? "Description is required" : "",
        fileMess:
          !data?.file?.name && data?.type == "create" ? "File is required" : "",
      });
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", data?.type);
      body.append("category_id", state.cat_id);
      body.append("sub_category_id", state.subCat_id);
      body.append("userid", user.id);
      body.append("title", data.title);
      body.append("description", data.desc);
      if (data.file) {
        body.append("image", data.file);
      }
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setData({});
        onClose();
        get_list();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // console.log("state ===", state);
  // console.log("user ===", user);
  // console.log("serviceData ===", serviceData);

  const get_service = async () => {
    if (!serviceData.desc || !serviceData.ID || fileData?.length == 0) {
      setServiceData({
        ...serviceData,
        IDMess: !serviceData.title ? "Service is required" : "",
        descMess: !serviceData.desc ? "Service Description is required" : "",
        fileMess: fileData?.length == 0 ? "Files is required" : "",
      });
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      // getservice(legal_advice_id,user_id,mobile_number,description,images[])
      body.append("action", "getservice");
      body.append("legal_advice_id", serviceData.ID);
      body.append("user_id", user.id);
      body.append("mobile_number", mobileNumber);
      body.append("description", serviceData.desc);
      body.append("images[]", fileData);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setServiceData({});
        setFileData([])
        onClose();
        get_list();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const get_list = async () => {
    try {
      setLoading1(true);
      const body = new FormData();
      body.append("action", "list");
      body.append("category_id", state.cat_id);
      body.append("sub_category_id", state.subCat_id);
      body.append("userid", user.id);

      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Advice_legal.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading1(false);
      if (res.success) {
        setDataList(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  useEffect(() => {
    get_list();
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state.name}
          titlelist={[
            { name: "Home", to: "/" },
            type == 1 && { name: "Our Offering", to: "/home" },
            { name: "Get Advice", to: "/get-advice" },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"} gap={"2"}>
          <CustomButton
            bg={"brand.primary"}
            title={"Services"}
            onClick={() => {
              if (type == 1) {
                navigate("/get-advice/services");
              } else if (type == 0) {
                navigate("/get-advice/services-user",{state:state});
              }
            }}
          />
          {/* {type == "1" && (
            <CustomButton
              icon={<IoMdAdd size={20} color="#fff" />}
              title={"Add"}
              onClick={() => {
                onOpen();
                setData({ type: "create" });
              }}
            />
          )} */}

          {type == "0" && (
            <CustomButton
              title={"Get Services"}
              onClick={() => {
                onOpen();
                setServiceData({ type: "custom" });
              }}
            />
          )}
        </Flex>
      </Flex>
      <Container maxW={"container.sm"} p={4}>
        {loading1 ? (
          <Loader />
        ) : dataList.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          dataList.map((item, index) => (
            <Box
              key={index}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "row"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
            >
              <Flex flexDirection={"column"} w={"80%"}>
                {/* <Text fontSize={16} color={"brand.text"} fontWeight={"600"}>
                  {item.Title}
                </Text>
                <Text fontSize={16} color={"brand.black"} fontWeight={"400"}>
                  {item.Description}
                </Text> */}
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={item?.Description}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Charges"}
                  value={`Rs. ${item?.Charges}`}
                />
              </Flex>
              <Flex w={"10%"} flexDirection={["row", "column"]} gap={"2"}>
                <CustomButton
                  title={"View"}
                  w="full"
                  onClick={() => {
                    setUrl(item.Document);
                    onOpen1();
                  }}
                />
                {/* "https://mylpg.pmpframe.com/association/documents/naveen-test--yX4PJznaJBrh.png" */}
                {/* {type == 1 && (
                  <CustomButton
                    w="full"
                    title={"Edit"}
                    bg={"brand.primary"}
                    onClick={() => {
                      onOpen();
                      setData({
                        ...data,
                        type: "edit",
                        title: item?.Title,
                        titleMess: "",
                        desc: item?.Description,
                        descMess: "",
                      });
                    }}
                  />
                )} */}
                {type == 0 && (
                  <CustomButton
                    w="full"
                    title={"Get"}
                    bg={"brand.primary"}
                    onClick={() => {
                      onOpen();
                      setServiceData({ ...item, type: "ind" });
                      // setLegal_advice_id
                    }}
                  />
                )}
              </Flex>
            </Box>
          ))
        )}
      </Container>
      <CustomModal
        onCancel={() => {
          setData({});
          setServiceData({});
          setFileData([]);
          setLoading(false)
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={`${
          type == 1 ? (data?.type == "create" ? "Add" : "Edit") : "Get"
        } ${state.name} ${type == 1 ? "" : "Service"}`}
      >
        <Container maxW={"container.sm"} p={4}>
          {/* {type == 1 ? (
            <>
              <InputBox
                label={"Title"}
                placeholder={"Title"}
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value, titleMess: "" });
                }}
                errorMess={data?.titleMess}
              />
              <InputBox
                area={true}
                label={"Description"}
                placeholder={"Description"}
                value={data.desc}
                onChange={(e) => {
                  setData({ ...data, desc: e.target.value, descMess: "" });
                }}
                errorMess={data?.descMess}
              />
              <SelectImage
                label={"Upload"}
                filename={data?.file?.name}
                onChange={(e) => {
                  setData({ ...data, file: e.target.files[0], fileMess: "" });
                }}
                errorMess={data?.fileMess}
              />
              <Center mt={5}>
                <CustomButton
                  title={"Submit"}
                  onClick={onAddAdvice}
                  loading={loading}
                />
              </Center>
            </>
          ) : (
            <> */}
              <Flex flexDirection={"column"}>
                {/* {serviceData?.Title && (
                  <Text>
                    Title : <b>{serviceData?.Title}</b>
                  </Text>
                )} */}
                {serviceData?.Description && (
                  <Text>
                    Service : <b>{serviceData?.Description}</b>
                  </Text>
                )}
              </Flex>
              {serviceData?.type == "custom" && (
                <SelectBox
                  label={"Services"}
                  placeholder={"Service"}
                  value={serviceData?.ID}
                  onChange={(v) => {
                    const data = dataList?.filter(
                      (val, i) => val?.ID == v.target.value
                    );
                    setServiceData({
                      ...serviceData,
                      ID: v.target.value,
                      IDMess: "",
                      Title: data ? data[0]?.Title : "",
                      Description: data ? data[0]?.Description : "",
                    });
                  }}
                  errorMess={serviceData?.IDMess}
                  options={dataList?.map((v, i) => (
                    <option key={i} value={v?.ID}>
                      {v?.Description}
                    </option>
                  ))}
                />
              )}
              <InputBox
                area={true}
                label={"Description"}
                placeholder={"Description"}
                value={serviceData.desc}
                onChange={(e) => {
                  setServiceData({
                    ...serviceData,
                    desc: e.target.value,
                    descMess: "",
                  });
                }}
                errorMess={serviceData?.descMess}
              />
              <SelectImage
                mt={4}
                multiple={true}
                label={"Upload Documents"}
                value={
                  fileData?.length > 0
                    ? fileData?.map((v) =>
                        v?.name ? `${v?.name},` : `${v?.image_url},`
                      )
                    : "Upload Documents"
                }
                filename={
                  fileData?.length > 0
                    ? fileData?.map((v) =>
                        v?.name ? `${v?.name},` : `${v?.image_url},`
                      )
                    : "Upload Documents"
                }
                onChange={(event) => {
                  const selectedFiles = Array.from(event.target.files);
                  setFileData(selectedFiles);
                  setServiceData({ ...serviceData, fileMess: "" });
                }}
                errorMess={data.fileMess}
              />
              <Center mt={5}>
                <CustomButton
                  title={"Get Service"}
                  onClick={get_service}
                  loading={loading}
                />
              </Center>
            {/* </>
          )} */}
        </Container>
      </CustomModal>
      <ImageModal url={url} isOpen={isOpen1} onClose={onClose1} />
    </Container>
  );
};

export default AddAdvice;
