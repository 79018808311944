import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { get_profile } from "../../../redux/dataSlice";
import store from "../../../redux/store";
import { Center, Flex, Stack, Text } from "@chakra-ui/react";
import { CustomButton, Loader, SelectBox } from "../../../utils/LayoutUtils";
import ShowData from "../../../components/ShowData";
import { PaymentCard } from "./PaymentCard";

const Payment = ({ title }) => {
  const { profile_data, profile_loading } = useSelector(
    (state) => state.allData
  );

  useEffect(() => {
    const body = new FormData();
    body.append("Agency_id", "12345678");
    body.append("type", 4);
    store.dispatch(get_profile(body));
  }, []);
  const data = profile_data[0];
  return (
    <>
      <Flex gap={5} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={18} color={"#2b3478"} fontWeight={600}>
          {title}
        </Text>
        {/* <CustomButton title={"Edit"} /> */}
      </Flex>
      {profile_loading ? (
        <Loader />
      ) : (
        <Stack gap={[1]}>
          <Flex gap={[1, 5]} mt={2} flexDirection={["column", "row"]}>
            <ShowData title={"Agency ID"} value={data?.Agency_id} flex={1} />
            <ShowData title={"Amount"} value={data?.Amount} flex={1} />
          </Flex>
          <Flex gap={[1, 5]} flexDirection={["column", "row"]}>
            <ShowData
              title={"Payment Pending"}
              value={data?.Payment_Pending}
              flex={1}
            />
            <ShowData title={"ASF Due"} value={"5000"} flex={1} />
          </Flex>
          <Flex gap={[1, 5]} flexDirection={["column", "row"]}>
            <SelectBox
              label={"Purpose"}
              options={
                <>
                  <option>ASF (Annual Subscription Fee)</option>
                  <option>Membership</option>
                  <option>Services</option>
                  <option>Donation</option>
                  <option>Contribution</option>
                </>
              }
            />
            <Flex flex={1} />
          </Flex>
          <Center mt={5}>
            <CustomButton title={"Submit"} />
          </Center>
        </Stack>
      )}

      <PaymentCard/>
    </>
  );
};

export default Payment;
