import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BreadCrumName from "../../../../../components/BreadCrumName";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  Loader,
  SelectImage,
} from "../../../../../utils/LayoutUtils";
import { ListDataShow, Table1 } from "../../../../../components/Compnent";
import { TableAddProd } from "../../../../../components/TableAddProd";
import CustomModal from "../../../../../components/CustomModal";

const InsuranceMain = () => {
  const [list, setlist] = useState([1, 2, 2, 3]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Insurance Request"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Our Offering", to: "/home" },
          { name: "Insurance", to: "/insurance" },
        ]}
      />
      <Container maxW={"container.md"} mt={[5, 5]} p={[1, 2, 3, 4]}>
        {loading ? (
          <Loader />
        ) : list.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          list.map((item, index) => (
            <Box
              key={index}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              display={"flex"}
              flexDirection={["column", "column"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={[5, "5%"]}
            >
              <Flex flexDirection={"column"} w={"100%"} gap={0}>
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Type of Request / Insurance"}
                  value={"Health"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Gender"}
                  value={"Male"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Age"}
                  value={"25"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"State"}
                  value={"Madhya Pradesh"}
                />
                <ListDataShow
                  f1={[4, 6]}
                  f2={[7, 14]}
                  title={"Description"}
                  value={"I want 1 cr. Health Cover and Accidental Cover also "}
                />
              </Flex>
              <Flex
                mt={2}
                w={["full", "30%"]}
                alignItems={"flex-end"}
                flexDirection={["row", "row"]}
                justifyContent={"flex-end"}
                gap={"2"}
              >
                <CustomButton
                  w="full"
                  title={"Send Quotation"}
                  bg={"brand.primary"}
                  onClick={() => onOpen()}
                />
              </Flex>
            </Box>
          ))
        )}
      </Container>
      <CustomModal
        onCancel={() => {
          setData({});
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={"Add Quotation"}
      >
        <Container maxW={"container.md"} p={2}>
          <Table1>
            <TableAddProd
              item={
                <SelectImage
                  doctype={".pdf,.xls,.xlsx"}
                  filename={data?.file?.name}
                  onChange={(e) => {
                    setData({ ...data, file: e.target.files[0], fileMess: "" });
                  }}
                  errorMess={data?.fileMess}
                />
              }
              lable={"Add Quotation"}
            />
          </Table1>
          <Center mt={5}>
            <CustomButton
              title={"Submit"}
              //   onClick={get_service}
              //   loading={loading}
            />
          </Center>
        </Container>
      </CustomModal>
    </Container>
  );
};

export default InsuranceMain;
