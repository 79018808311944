import React, { useEffect, useRef } from "react";
import HomeSlider from "../../components/HomeSlider";
import {
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import About from "./About";
import OverTeam from "./OverTeam";
import Initiatives from "./Initiatives";
import JoinUs from "./JoinUs";
import Footer from "../../components/Footer";
import GalleryMain from "./GalleryMain";
import Hiring from "./Hiring";
import { Outlet, useNavigate } from "react-router-dom";
import { images } from "../../utils/utils";
import { CustomButton } from "../../utils/LayoutUtils";
import { IoMenu } from "react-icons/io5";
import PlansAndPrices from "./PlansAndPrices";
import { DrawerItem, HeaderItems } from "../../components/Compnent";
import store from "../../redux/store";
import {
  get_about_us_home,
  get_gallery_img,
  get_hiring,
  get_initiatives_data,
  get_initiatives_img,
  get_join_us,
  get_our_team,
  get_privacy_policies,
  get_refund_policies,
  get_slider_data,
  get_terms_and_conditions,
} from "../../redux/HomeSlice";
import { useSelector } from "react-redux";

const Main = () => {
  return (
    <VStack
      h={"100vh"}
      minH={"100vh"}
      maxW={"full"}
      spacing={0}
      minW={"100wh"}
      overflowY={"auto"}
      m={0}
      p={0}
    >
      <Stack
        h={70}
        position={"sticky"}
        // bg="#2b3478"
        bg={"#2b3478"}
        w={"full"}
        justify={"center"}
        py={2}
        spacing={0}
      >
        <Header />
      </Stack>
      <Stack
        overflowY={"auto"}
        w={"full"}
        bg={"#f8f9fe"}
        h={"full"}
        maxW={"full"}
        spacing={0}
      >
        <Outlet />
        <Spacer />
      </Stack>
    </VStack>
  );
};
export default Main;

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();
  const onTabClick = (targetElementId) => {
    const element = document.getElementById(targetElementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const location = window.location;

  return (
    <Flex
      h={70}
      w="100vw"
      minW={"100vw"}
      maxW="1400px"
      px={5}
      alignItems={"center"}
      gap={10}
      justifyContent={"space-between"}
    >
      <Flex gap={5} alignItems={"center"}>
        <Image
          src={images.logo}
          h={["50px", "60px"]}
          w={["50px", "60px"]}
          objectFit={"contain"}
          cursor={"pointer"}
          borderRadius={6}
          bg={"brand.white"}
          p={1}
          onClick={() => {
            navigate("/");
            onTabClick("slider");
          }}
        />
        {location?.pathname == "/register" ||
        location?.pathname == "/terms" ||
        location?.pathname == "/reset-password" ||
        location?.pathname == "/login" ? (
          ""
        ) : (
          <Flex gap={5} display={["none", "none", "none", "flex"]}>
            <HeaderItems
              title={"About Us"}
              onClick={() => {
                onTabClick("about");
              }}
            />

            <HeaderItems
              title={"Our Team"}
              onClick={() => {
                onTabClick("team");
              }}
            />

            <HeaderItems
              title={"Initiatives"}
              onClick={() => {
                onTabClick("initiatives");
              }}
            />

            <HeaderItems
              title={"Our Hiring"}
              onClick={() => {
                onTabClick("hiring");
              }}
            />
            <HeaderItems
              title={"Join With Us"}
              onClick={() => {
                onTabClick("joinus");
              }}
            />
            <HeaderItems
              title={"Gallery"}
              onClick={() => {
                onTabClick("gallery");
              }}
            />
            {/* <HeaderItems title={"Plans and Prices"} onClick={()=>{
                onTabClick("plans");
            }}/> */}
            <HeaderItems
              title={"Contact Us"}
              onClick={() => {
                onTabClick("contact");
              }}
            />
          </Flex>
        )}
      </Flex>

      <Flex
        gap={5}
        alignItems={"center"}
        display={["none", "none", "none", "flex"]}
      >
        <CustomButton
          title={"New User"}
          bg={"#d1262b"}
          onClick={() => navigate("/register")}
        />
        <CustomButton
          title={"Login"}
          bg={"#d1262b"}
          onClick={() => navigate("/login")}
        />
      </Flex>
      <Flex
        gap={5}
        alignItems={"center"}
        display={["flex", "flex", "flex", "none"]}
      >
        <IoMenu
          color="#fff"
          size={25}
          cursor={"pointer"}
          onClick={() => onOpen()}
        />
      </Flex>
      
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image
              src={images.logo}
              h={["50px", "60px"]}
              w={["50px", "60px"]}
              objectFit={"contain"}
              cursor={"pointer"}
              borderRadius={6}
              bg={"brand.white"}
              p={1}
              onClick={() => {
                navigate("/");
                onTabClick("slider");
                onClose();
              }}
            />
          </DrawerHeader>

          <DrawerBody>
            <Flex gap={5} flexDirection={"column"}>
              {location?.pathname == "/register" ||
              location?.pathname == "/terms" ||
              location?.pathname == "/reset-password" ||
              location?.pathname == "/login" ? (
                ""
              ) : (
                <>
                  <DrawerItem
                    title={"About Us"}
                    onClick={() => {
                      onClose();
                      onTabClick("about");
                    }}
                  />
                  <DrawerItem
                    title={"Our Team"}
                    onClick={() => {
                      onClose();
                      onTabClick("team");
                    }}
                  />
                  <DrawerItem
                    title={"Initiatives"}
                    onClick={() => {
                      onClose();
                      onTabClick("initiatives");
                    }}
                  />
                  <DrawerItem
                    title={"Our Hiring"}
                    onClick={() => {
                      onClose();
                      onTabClick("hiring");
                    }}
                  />
                  <DrawerItem
                    title={"Join With Us"}
                    onClick={() => {
                      onClose();
                      onTabClick("joinus");
                    }}
                  />
                  <DrawerItem
                    title={"Gallery"}
                    onClick={() => {
                      onClose();
                      onTabClick("gallery");
                    }}
                  />
                  {/* <DrawerItem
                title={"Plans and Prices"}
                onClick={() => {
                  onClose();
                  onTabClick("plans");
                }}
              /> */}
                  <DrawerItem
                    title={"Contact Us"}
                    onClick={() => {
                      onClose();
                      onTabClick("contact");
                    }}
                  />
                </>
              )}

              <DrawerItem
                title={"Login"}
                onClick={() => {
                  navigate("/login");
                  onClose();
                }}
              />
              <DrawerItem
                title={"New User"}
                onClick={() => {
                  navigate("/register");
                  onClose();
                }}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export const HomeContent = () => {
  const {
    our_team,
    about_us,
    slider_data,
    hiring,
    initiatives_img,
    initiatives_data,
    join_with_us,
    gallery_img,
    privacy_policies,
    refund_policies,
  } = useSelector((state) => state.homeData);
  useEffect(() => {
    store.dispatch(get_our_team());
    store.dispatch(get_slider_data());
    store.dispatch(get_hiring());
    store.dispatch(get_initiatives_data());
    store.dispatch(get_initiatives_img());
    store.dispatch(get_join_us());
    store.dispatch(get_gallery_img());
    store.dispatch(get_refund_policies());
    store.dispatch(get_privacy_policies());
    store.dispatch(get_terms_and_conditions());
    store.dispatch(get_about_us_home());
  }, []);
  return (
    <>
      <Container maxW={"container"} py={0} px={0} m={0}>
        <section id="slider">
          <Flex
            maxW={"container"}
            h={["80vw", "100vh", "calc(100vh - 70px)", "calc(100vh - 70px)"]}
            py={0}
            px={0}
            m={0}
          >
            <HomeSlider data={slider_data} />
          </Flex>
        </section>
        <Container
          maxW={"container"}
          display={"flex"}
          flexDirection={"column"}
          gap={[4, 6, 8, 10]}
          p={[4, 4, 6, 8]}
        >
          <section id="about">
            <About data={about_us} />
          </section>
          <section id="team">
            <OverTeam data={our_team} />
          </section>
          <section id="initiatives">
            <Initiatives img={initiatives_img} data={initiatives_data} />
          </section>
          <section id="hiring">
            <Hiring data={hiring} />
          </section>
          <section id="joinus">
            <JoinUs data={join_with_us} />
          </section>
          <section id="gallery">
            <GalleryMain data={gallery_img} />
          </section>
          {/* <section id="plans">
            <PlansAndPrices />
          </section> */}
        </Container>
        <section id="contact">
          <Footer refund={refund_policies} privacy={privacy_policies} />
        </section>
      </Container>
    </>
  );
};
