import React, { useState } from "react";
import "./Profile.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { CustomButton } from "../../../utils/LayoutUtils";
import { color } from "framer-motion";

export const PaymentCard = () => {
  const [hover1, setHover1] = useState("brand.primary");
  const [hover2, setHover2] = useState("brand.primary");
  return (
    <section className="page-contain">
      <a
        href=""
        className="data-card"
        onMouseEnter={() => setHover1("brand.btn")}
        onMouseLeave={() => setHover1("brand.primary")}
      >
        <h3>Rs. 500</h3>
        <h4>Subscription Charges</h4>
        <p>
          Subscribe for just Rs. 500 to access premium content and exclusive
          features. Enjoy uninterrupted service and stay updated with the latest
          offerings. Pay now
        </p>
        <CustomButton
          bg={hover1}
          w={"full"}
          rightIcon={<FaArrowRightLong />}
          title={"Pay Now"}
        />
      </a>
      <a
        href=""
        className="data-card"
        onMouseEnter={() => setHover2("brand.btn")}
        onMouseLeave={() => setHover2("brand.primary")}
      >
        <h3>Rs. 5000</h3>
        <h4>Annual Fee</h4>
        <p>
          Pay an annual fee of Rs. 5000 to enjoy uninterrupted access to premium
          services, exclusive benefits, and priority support for a full year.
          Join us today!
        </p>

        <CustomButton
          bg={hover2}
          w={"full"}
          rightIcon={<FaArrowRightLong color="brand.primary" />}
          title={"Pay Now"}
        />
      </a>
    </section>
  );
};
